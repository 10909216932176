import React from 'react';
import './course-of-illness-table.scss';

import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import generateID from 'components/generate-id/generate-id';

export default function CourseOfIllnessTable(json: any) {
  // const monthView = json.json.data.map((arrayElement: Array<string>) => {

  // });

  return (
    <div className="course-of-illness-table">
      <Container>
        <Row>
          <Col>
            <Table striped bordered hover variant="dark" responsive="sm">
              <thead>
                <tr>
                  {json.json !== {} && json.json.headers !== undefined && json.json.headers.map((currentValue: Array<string>) =>
                    <td key={generateID()}>{currentValue}</td>
                  )}
                </tr>
              </thead>
              <tbody>
                {json.json !== {} && json.json.data !== undefined && json.json.data.map((arrayElement: Array<string>) => {
                  return <tr key={generateID()}>
                    {Object.values(arrayElement).map((arrayElementInArrayElement: string) => {
                      return <td key={generateID()}>{arrayElementInArrayElement}</td>;
                    })}
                  </tr>;
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
