import React from 'react';
import './course-of-illness-calendar-view.scss';

import { Alert } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { ToggleButton } from 'react-bootstrap';
import generateID from 'components/generate-id/generate-id';
import moment from 'moment';

export default function CourseOfIllnessCalendarView(json: any) {

  const colors = {
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    light: 'light',
    dark: 'dark',
  };

  const startDay = moment().clone().startOf('month').startOf('week');
  const endDay = moment().clone().endOf('month').endOf('week');

  // console.log('startDay:', startDay);
  // console.log('endDay:', endDay);
  // console.log('json:', json);

  let calendar = [];
  let index = startDay.clone();

  while (index.isSameOrBefore(endDay, 'day')) {
    calendar.push(new Array(7).fill({}).map(() => {
      const indexClone = index.clone();
      const data: any = json.json.data.filter((element: any) => moment(element.date.split(',')[1]).isSame(index));
      index.add(1, 'day');
      return {
        year: indexClone.year(),
        month: indexClone.month(),
        day: indexClone.date(),
        week: indexClone.format('dddd'),
        data,
      };
    }));
  }

  console.log('calendar:', calendar);

  const [radioValue, setRadioValue] = React.useState('1');

  const radios = [
    { name: 'Month', value: '1' },
    { name: 'Week', value: '2' },
    { name: 'Day', value: '3' },
    { name: 'List', value: '4' },
  ];

  return (
    <div className="course-of-illness-calendar-view">
      <Container>
        <Row>
          <Col>
            <ButtonGroup toggle>
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  type="radio"
                  variant="info"
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => setRadioValue(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover variant="dark" responsive="sm">
              <thead>
                <tr>
                  {calendar[0].map(calendarElemenet => <td key={generateID()} className="callendar-week">{calendarElemenet.week.slice(0, 1).toUpperCase()}</td>)}
                </tr>
              </thead>
              <tbody>
                {calendar.map(calendarWeek =>
                  <tr key={generateID()}>
                    {calendarWeek.map(calendarDay =>
                      <td key={generateID()} className={calendarDay.day === +moment().format('D') ? 'today' : undefined}>
                        <p className="callendar-day">{calendarDay.day}</p>
                        {calendarDay.data.map((calendarData: any) =>
                          <Alert variant={moment(calendarData.date.split(',')[1]).isSame(moment(), 'month') ? colors.light : colors.dark}>
                            {calendarData.time && <p>{calendarData.time}</p>}
                            {(calendarData.medicine || calendarData.ml) && <><hr /><p>{calendarData.medicine && calendarData.medicine} {calendarData.ml && <>{calendarData.ml}&nbsp;ml</>}</p></>}
                            {calendarData.temperature && <><hr /><p>{calendarData.temperature}&nbsp;<sup>o</sup>C</p></>}
                            {calendarData.note && <><hr /><p>{calendarData.note}</p></>}
                          </Alert>
                        )}
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
