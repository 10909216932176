import React from 'react';
import './App.scss';

import 'moment/locale/pl';
import axios from 'axios';
import CourseOfIllnessCalendarView from 'components/course-of-illness-calendar-view/course-of-illness-calendar-view';
import CourseOfIllnessTable from 'components/course-of-illness-table/course-of-illness-table';
import logo from './logo.svg';
import moment from 'moment';
import mockCourseOfIllnessJson from 'mocks/course-of-illness.json';

export default function App() {
  const [courseOfIllnessJson, setCourseOfIllnessJson] = React.useState<any>(mockCourseOfIllnessJson);
  const [releaseVersionNumberJson, setReleaseVersionNumberJson] = React.useState<any>({});

  moment.locale('pl');

  React.useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          (async () => await axios.get('course-of-illness.json'))(),
          (async () => await axios.get('release-version-number.json'))(),
        ]).then(res => {
          setCourseOfIllnessJson(res[0].data);
          setReleaseVersionNumberJson(res[1].data);
        });
      } catch (err) {
        console.log('err:', err);
      }
    })();
  }, []);

  // console.log('courseOfIllnessJson:', courseOfIllnessJson);
  // console.log('releaseVersionNumberJson:', releaseVersionNumberJson);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kami uczy się programować
        </a>
        {releaseVersionNumberJson.version && (<p className="App-footer App-footer-first-element">Wersja aplikacji: {releaseVersionNumberJson.version}</p>)}
        {releaseVersionNumberJson.develop && (<p className="App-footer">Data wypuszczenia na środowisko developerskie: {moment(releaseVersionNumberJson.develop).format('LLLL')}</p>)}
        {releaseVersionNumberJson.test && (<p className="App-footer">Data wypuszczenia na środowisko testowe: {moment(releaseVersionNumberJson.test).format('LLLL')}</p>)}
        {releaseVersionNumberJson.master && (<p className="App-footer">Data wypuszczenia na produkcję: {moment(releaseVersionNumberJson.master).format('LLLL')}</p>)}
      </header>
      <CourseOfIllnessCalendarView json={courseOfIllnessJson} />
      <CourseOfIllnessTable json={courseOfIllnessJson} />
    </div>
  );
}
